<template>
  <swiper
    :direction="'vertical'"
    :freeMode="isfree"
    :modules="modules"
    :mousewheel="true"
    class="mySwiper"
    id="mySwiper"
    @slideChange="slideChange"
  >
    <swiper-slide>
      <P1Page />
    </swiper-slide>
    <swiper-slide>
      <P2Page />
    </swiper-slide>
    <swiper-slide>
      <P3Page />
    </swiper-slide>
    <swiper-slide style="display: flex; flex-direction: column">
      <P4Page ref="P4PageComp" />
    </swiper-slide>
  </swiper>
  <Menu :pageIndex="pageIndex" :slideTo="slideTo" />
</template>
<script>
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/vue";

import P1Page from "./p1Page.vue";
import P2Page from "./p2Page.vue";
import P3Page from "./p3Page.vue";
import P4Page from "./p4Page.vue";
import Menu from "../widget/menuWidget.vue";
import { FreeMode,Mousewheel } from "swiper";

export default {
  components: {
    Swiper,
    Menu,
    SwiperSlide,
    P1Page,
    P2Page,
    P3Page,
    P4Page,
  },
  data() {
    const titleList = [
      "FRONT PAGE",
      "BACKGROUND",
      "GAME ROLE",
      "GAME FEATURES",
    ];
    const isfree=false//screen.width/screen.height>1920/1280;
    return {
      titleList,
      pageIndex: 0,
      isfree,
    };
  },
  setup() {
    return {
      modules: [FreeMode,Mousewheel],
    };
  },
  methods: {
    slideChange(swiper) {
      const { activeIndex } = swiper;
      console.log(activeIndex);
      this.pageIndex = activeIndex;
      console.log("pageIndex", this.pageIndex);
    },
    mousedown(swipe, e) {
      const { activeIndex } = swipe;
      if (activeIndex != 3) return;
      this.$refs.P4PageComp.dragInit(e);
    },
    mouseup(swipe, e) {
      const { activeIndex } = swipe;
      if (activeIndex != 3) return;
      this.$refs.P4PageComp.draghandle(e);
    },
    slideTo(to) {
      const swiper = document.getElementById("mySwiper").swiper;
      swiper.slideTo(to);
    },
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
footer {
  width: 100%;
  height: 5rem;
  background: rgba(0, 0, 0, 1);
}
</style>