<template>
  <div class="page">
    <PagePagination :pageIndex="4" />
    <div class="title">
      <img class="decorate_L" alt="" />
      <img class="titleFonte" alt="" />
      <img class="decorate_R" alt="" />
    </div>
    <div class="Swipercontainer">
      <swiper
        :effect="'coverflow'"
        :grabCursor="true"
        :centeredSlides="true"
        :slidesPerView="1.45"
        :initialSlide="3"
        :loop="true"
        :autoplay="true"
        :coverflowEffect="{
          rotate: 0,
          stretch: '0%',
          depth: 300,
          modifier: 1,
          slideShadows: true,
        }"
        :pagination="true"
        :modules="modules"
        @slideChange="slideChange"
        id="mySwiper4"
      >
        <swiper-slide v-for="cell in list" :key="cell.id">
          <img :src="cell.imgURL" alt="" />
        </swiper-slide>
      </swiper>
      <img class="btnPre" @click="slidePrev" alt="" />
      <img class="btnNext" @click="slideNext" alt="" />
      <div class="introImgIndex">
        <div v-for="(cell, index) in list" :key="cell.id" class="pos">
          <img
            v-if="activeIndex % list.length == index"
            class="active"
            alt=""
          />
          <img v-else class="unActive" alt="" />
        </div>
      </div>
    </div>
    <footer>
     <img class="decorateTop" alt="">
     <div class="iconBox">
         <img class="icon_decorateLeft" alt="">
      <div class="icon" @click="toFaceBook">
         <img src="../assets/p4/4tu_btn_3.png" alt="">
       </div>
         <img class="icon_decorateMiddle" alt="">
       <div class="icon" @click="toReddit">
         <img src="../assets/p4/4tu_btn_5.png" alt="">
       </div>
         <img class="icon_decorateRight" alt="">
     </div> 
     <a class='policy'  href="http://nmd.ziipin-ar.com/terms/privacy_ar.html">{{_T('隐私协议')}}</a>
     <p class='info'>{{_T('底部说明')}}</p>
    </footer>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import PagePagination from "../widget/paginationWidget.vue";
import { EffectCoverflow,Autoplay, Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
function _T(t) {
  let translationMap = require("../translation.json");
  return translationMap[t] ? "\u202b" + translationMap[t] + "\u202b" : t;
}
export default {
  components: {
    Swiper,
    SwiperSlide,
    PagePagination,
  },
  data() {
    const list=[
        {
          id: "re_0",
          imgURL: require("../assets/p4/poster1.png"),
        },

        {
          id: "re_1",
          imgURL: require("../assets/p4/poster2.png"),
        },

        {
          id: "re_2",
          imgURL: require("../assets/p4/poster3.png"),
        },
        {
          id: "re_3",
          imgURL: require("../assets/p4/poster4.png"),
        },
        {
          id: "re_4",
          imgURL: require("../assets/p4/poster5.png"),
        },
      ];
    return {
       list,
      activeIndex: 0,
    };
  },
  setup() {
    return {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      modules: [Pagination, Autoplay,EffectCoverflow],
    };
  },
  methods: {
    _T,
    slideChange(swiper) {
      const { activeIndex } = swiper;
      this.activeIndex = activeIndex;
    },

    slidePrev() {
      const swiper = document.getElementById("mySwiper4").swiper;
      swiper.slidePrev();
    },
    slideNext() {
      const swiper = document.getElementById("mySwiper4").swiper;
      swiper.slideNext();
    },
    toFaceBook(){
      const targetUrl='https://www.facebook.com/ArabianParkourGame'
      window.location=targetUrl
    },
    toReddit(){
      const targetUrl='https://www.reddit.com/r/Desert_Legend/'
      window.location=targetUrl
    }
  },
};
</script>
<style scoped>
.page {
  width: 100%;
  height: 100%;
  background: url(../assets/p4/4tu_bg_1.jpg);
  background-size: 100% 100%;
}
.Swipercontainer {
  width: 22rem;
  height: 15rem;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  margin: auto;
  left: -1rem;
  right: 0;
  top: -3rem;
  bottom: 0;
}
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 70%;
  height: 100%;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.swiper-button-next {
  width: 1rem;
  height: 1rem;
  color: red;
}
.Swipercontainer .btnPre {
  width: 1.575rem;
  height: 2.11875rem;
  content: url(../assets/p4/4tu_btn_2.png);
  position: absolute;
  margin: auto;
  top: -1.5rem;
  bottom: 0;
  left: 1rem;
  z-index: 1;
}
.Swipercontainer .btnNext {
  width: 1.575rem;
  height: 2.11875rem;
  content: url(../assets/p4/4tu_btn_2.png);
  transform: rotate(180deg);
  position: absolute;
  margin: auto;
  top: -1.5rem;
  bottom: 0;
  right: 1rem;
  z-index: 1;
}
.title {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 1.5rem;
  width: 10rem;
  height: fit-content;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-slide .titleFonte {
  width: 6.375rem;
  height: 1.1825rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  content: url(../assets/p4/4tu_txt_1.png);
}
.decorate_R {
  width: 4rem;
  height: 0.7rem;
  content: url(../assets/p3/3tu_txt_2.png);
}
.decorate_L {
  width: 4rem;
  height: 0.7rem;
  transform: rotate(180deg);
  content: url(../assets/p3/3tu_txt_2.png);
}
.introImgIndex {
  width: 15rem;
  height: 0.33rem;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 1.7rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: url(../assets/p3/3tu_btn_10.png);
  background-size: 100% 100%;
}
.introImgIndex .pos {
  width: 0.31875rem;
  height: 100%;
}
.introImgIndex .pos .unActive {
  width: 0.31875rem;
  height: 0.31875rem;
  content: url(../assets/p4/4tu_btn_1.png);
}
.introImgIndex .pos .active {
  width: 0.4312rem;
  height: 0.4312rem;
  position: relative;
  top: -0.07rem;
  content: url(../assets/p3/3tu_btn_8.png);
}
footer{
  width:100%;
  height:5rem;
  background:rgba(0, 0, 0, 0);
  position:absolute;
  bottom:0;
  left:0;
  z-index: 1;
  text-align: center;
}
footer .decorateTop{
  width:29.2125rem;
  height: .26rem;
  content:url(../assets/p4/4tu_btn_9.png);
  margin:auto;
  margin-top:.1rem;
}
footer .iconBox{
  width:15rem;
  height: 1.7rem;
  background:rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  margin:auto;
  margin-top:.3rem;
}
footer .iconBox .icon{
  width:1.6125rem;
  height:1.6125rem;
  background:url(../assets/p4/4tu_btn_6.png);
  background-size:100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer .iconBox .icon img{
  width:65%
}
footer .iconBox .icon_decorateLeft{
  content: url(../assets/p4/4tu_btn_7.png);
  width:3.75rem;
  height: .375rem;
}
footer .iconBox .icon_decorateMiddle{
  content: url(../assets/p4/4tu_btn_8.png);
  width:2.71875rem;
  height: .375rem;
}
footer .iconBox .icon_decorateRight{
  content: url(../assets/p4/4tu_btn_7.png);
  transform:rotate(180deg);
  width:3.75rem;
  height: .375rem;
}
footer .policy{
  color:#bdaea1;
  font-size: .4rem;
  position:absolute;
  margin: auto;
  left:0;
  right:0;
  bottom:1.8rem;
}
footer .info{
  color:#bdaea1;
  font-size: .4rem;
  position:absolute;
  margin: auto;
  left:0;
  right:0;
  bottom:1.2rem;
}
@media (max-width: 450px) {
  .title {
    transform: scale(2);
    top: 5rem;
  }
  .page {
    background: url(../assets/mobile/4tu_bg_4.jpg);
  }
  .Swipercontainer{
    transform: scale(1.8);
    left:0;
  }
  .Swipercontainer .btnNext{
    top: 4.5rem;
    right: 1.5rem;
  }
   .Swipercontainer .btnPre{
    top: 4.5rem;
    left: 1.5rem;
  }
  .introImgIndex{
        bottom: -3rem;
    transform: scale(1.2);
  }
  .introImgIndex .pos{
    transform: scale(1.2);
  }
  footer{
    transform: scale(2);
  }
  footer .decorateTop{
  width:17.5rem;
  height: .35rem;
  content:url(../assets/mobile/4tu_bg_6.png);
  }
}
</style>
