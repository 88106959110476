<template>
  <div class="pagination">
    <p
      v-for="(title, index) in titleList"
      :class="{ isActive: index == pageIndex }"
      :key="title"
      @click="slideTo(index)"
    >
      <img class="activeBg" v-if="index == pageIndex" alt="" />
      {{ title }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    pageIndex: Number,
    slideTo: Function,
  },
  data() {
    const titleList = [
      "FRONT PAGE",
      "BACKGROUND",
      "GAME ROLE",
      "GAME FEATURES",
    ];
    return {
      titleList,
    };
  },
};
</script>

<style scoped>
.pagination {
  width: 9rem;
  height: 10rem;
  background: rgba(0, 0, 0, 0);
  line-height: 1;
  position: absolute;
  margin: auto;
  top: -7.5rem;
  bottom: 0;
  right: -0.5rem;
  z-index: 1;
  color: #fff;
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
}
.pagination p.isActive {
  width: 100%;
  position: relative;
  text-align: center;
}
.pagination p.isActive::before {
  content: "";
  background-image: url(../assets/p1/1tu_bg_3.png);
  background-size: 100% 100%;
  width: 0.675rem;
  height: 0.53rem;
  display: inline-block;
  margin-right: 0.3rem;
}
.pagination p.isActive::after {
  content: "";
  background-image: url(../assets/p1/1tu_bg_3.png);
  background-size: 100% 100%;
  width: 0.675rem;
  height: 0.53rem;
  display: inline-block;
  transform: rotate(180deg);
  margin-left: 0.3rem;
}
.pagination .activeBg {
  width: 3.13125rem;
  height: 1.6875rem;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: url(../assets/p1/1tu_bg_4.png);
}
@media (max-width: 450px) {
  .pagination {
    transform: scale(2);
    right: 4rem;
    bottom: auto;
    top: 14rem;
  }
}
</style>