<template>
  <div class="page">
    <swiper
      @slideChange="slideChange"
      id="mySwiper3"
      :loop="true"
      :autoplay="true"
      :modules="modules"
    >
      <swiper-slide v-for="role in CharacterList" :key="role.name">
        <div class="title">
          <img class="decorate_L" alt="" />
          <img class="titleFonte" alt="" />
          <img class="decorate_R" alt="" />
        </div>
        <PagePagination :pageIndex="3" />
        <img
          class="character_bg"
          :src="isMobileEnv ? role.mobileBg : role.bg"
          alt=""
        />
        <img
          v-if="!isMobileEnv"
          class="character_bg"
          src="../assets/p3/3tu_juese_2.png"
        />
        <img v-else class="character_bg" src="../assets/mobile/3tu_bg_0.png" />
        <div
          class="roleBox RoleOffse"
        >
          <img :class="getShadow" :src="role.shadow" alt="" />
          <img
            :src="role.profile"
            class="character"
            :class="getCharacterClass"
            alt=""
          />
        </div>
        <div class="describ" :style="{ color: role.fontColor }">
          <img class="bg" :src="role.describBg" alt="" />
          <p class="heroName">{{ role.intro.name }}</p>
          <p class="tag">
            <img class="icon" :src="role.intro.tagIcon" alt="" />
            {{ role.intro.tag }}
          </p>
          <p class="heroBackground">
            {{ role.intro.heroBackground }}
          </p>
          <p class="nature">
            {{ _T("性格") }}<br />
            {{ role.intro.nature }}
          </p>
          <div class="skill">
            {{ _T("天赋技能") }}{{ role.intro.skill }}<br />
            <div class="icon">
              <img :src="role.intro.skillIcon" alt="" />
            </div>
          </div>
        </div>
      </swiper-slide>
      <div class="characterIndex">
        <div
          v-for="icon in 3"
          :key="icon"
          :class="getIconClass(roleIndex, icon)"
          @click="slideTo(icon)"
        >
          <img :src="getIcon(roleIndex, icon)" alt="" />
          <img class="bottomDecorate" alt="" />
          <img v-if="currentCharacter.name != 'C3'" class="guid2Left" alt="" />
          <img v-if="currentCharacter.name != 'C1'" class="guid2Right" alt="" />
        </div>
        <img class="bar" alt="" />
      </div>
    </swiper>
  </div>
</template>

<script>
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Swiper, SwiperSlide } from "swiper/vue";
import PagePagination from "../widget/paginationWidget.vue";
import { Autoplay } from "swiper";

function _T(t) {
  let translationMap = require("../translation.json");
  return translationMap[t] ? "\u202b" + translationMap[t] + "\u202b" : t;
}

export default {
  components: {
    Swiper,
    PagePagination,
    SwiperSlide,
  },
  data() {
    const CharacterList = [
      {
        name: "C1",
        describBg: require("../assets/p3/3tu_bg_1.png"),
        bg: require("../assets/p3/3tu_bg_2.jpg"),
        mobileBg: require("../assets/mobile/3tu_bg_1.jpg"),
        profile: require("../assets/p3/3tu_juese_1.png"),
        shadow: require("../assets/p3/3tu_juese_1.1.png"),
        icon: require("../assets/p3/3tu_btn_1.png"),
        icon_unActive: require("../assets/p3/3tu_btn_2.png"),
        fontColor: "#aeded3",
        intro: {
          name: _T("贾比尔"),
          tag: _T("驭风人"),
          tagIcon: require("../assets/p3/3tu_icon_1.png"),
          nature: _T("贾比尔性格"),
          heroBackground: _T("贾比尔背景"),
          skill: _T("狂暴风刃"),
          skillIcon: require("../assets/p3/skill_1.png"),
        },
      },
      {
        name: "C2",
        describBg: require("../assets/p3/3tu_bg_3.png"),
        bg: require("../assets/p3/3tu_bg_4.jpg"),
        mobileBg: require("../assets/mobile/3tu_bg_2.jpg"),
        profile: require("../assets/p3/3tu_juese_3.png"),
        shadow: require("../assets/p3/3tu_juese_3.1.png"),
        icon: require("../assets/p3/3tu_btn_3.png"),
        icon_unActive: require("../assets/p3/3tu_btn_4.png"),
        fontColor: "#ead7a9",
        intro: {
          name: _T("达莉亚"),
          tag: _T("不焚者"),
          tagIcon: require("../assets/p3/3tu_icon_2.png"),
          nature: _T("达莉亚性格"),
          heroBackground: _T("达莉亚背景"),
          skill: _T("火舞焚天"),
          skillIcon: require("../assets/p3/skill_2.png"),
        },
      },
      {
        name: "C3",
        describBg: require("../assets/p3/3tu_bg_5.png"),
        bg: require("../assets/p3/3tu_bg_6.jpg"),
        mobileBg: require("../assets/mobile/3tu_bg_3.jpg"),
        profile: require("../assets/p3/3tu_juese_4.png"),
        shadow: require("../assets/p3/3tu_juese_4.1.png"),
        icon: require("../assets/p3/3tu_btn_5.png"),
        icon_unActive: require("../assets/p3/3tu_btn_6.png"),
        fontColor: "#cc9f90",
        intro: {
          name: _T("阿什拉"),
          tag: _T("稳重儒雅的书法家"),
          tagIcon: require("../assets/p3/3tu_icon_3.png"),
          nature: _T("阿什拉性格"),
          heroBackground: _T("阿什拉背景"),
          skill: _T("沙之分身"),
          skillIcon: require("../assets/p3/skill_3.png"),
        },
      },
    ];
    const isMobileEnv = screen.width < 450;
    return {
      isMobileEnv,
      CharacterList,
      currentCharacter:CharacterList[0],
      roleIndex: 0,
    };
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
  computed: {
    getShadow() {
      switch (this.roleIndex) {
        case 0:
          return "shadow0";
        case 1:
          return "shadow1";
        case 2:
          return "shadow2";
        default:
          return "shadow0";
      }
    },
    getCharacterClass() {
      switch (this.roleIndex) {
        case 0:
          return "chara1";
        default:
          return "";
      }
    },
  },
  methods: {
    _T,
    slideChange(swiper) {
      const { activeIndex } = swiper;
      console.log('activeIndex',activeIndex);
      const calcIndex=activeIndex<=0?0:((activeIndex-1)%this.CharacterList.length)
      this.roleIndex = calcIndex;
      this.currentCharacter=this.CharacterList[calcIndex]
    },
    getIconClass(characterIndex, iconIndex) {
      if (characterIndex == iconIndex - 1) return "icon";
      else return "icon_unActive";
    },
    getIcon(characterIndex, iconIndex) {
      if (characterIndex == iconIndex - 1)
        return this.CharacterList[iconIndex - 1].icon;
      else return this.CharacterList[iconIndex - 1].icon_unActive;
    },
    slideTo(to) {
      const swiper = document.getElementById("mySwiper3").swiper;
      console.log(to,swiper)
     swiper.slideTo(to);
    },
  },
};
</script>

<style scoped>
.page {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
img {
  width: 100%;
}
.character_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.roleBox {
  width: 20.3rem;
  height: 16rem;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  left: 0rem;
  margin: auto;
  top: 4rem;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.roleBox .character {
  width: auto;
  height: 100%;
  z-index: 1;
}
.chara1 {
  margin-left: -5rem;
}
.roleBox .shadow0 {
  position: absolute;
  bottom: 0.3rem;
  width: 17.1rem;
  height: 3.7rem;
  left: 5rem;
}
.roleBox .shadow2 {
  position: absolute;
  bottom: 0;
  width: 12rem;
  left: 6.7rem;
  bottom: 0.3rem;
}
.roleBox .shadow1 {
  position: absolute;
  bottom: 0;
  width: 19.76rem;
  bottom: 0.3rem;
  width: 19.76rem;
  left: 4.8rem;
}
.describ {
  width: 7.875rem;
  height: 12.375rem;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  margin: auto;
  top: -1rem;
  bottom: 0;
  right: 8rem;
  z-index: 1;
}
.describ img.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.describ .heroName {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0.8rem;
  font-size: 1rem;
  line-height: 1;
}
.describ .tag {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 2.1rem;
  font-size: 0.3rem;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.5rem;
}
.describ .tag .icon {
  width: 0.46875rem;
  height: 0.46875rem;
  margin-right: 0.1rem;
}

.describ .heroBackground {
  width: 85%;
  margin: auto;
  position: absolute;
  top: 3.5rem;
  left: 0;
  right: 0;
  font-size: 0.4rem;
  line-height: 1.5;
}
.describ .nature {
  width: 85%;
  margin: auto;
  position: absolute;
  top: 7rem;
  left: 0;
  right: 0;
  font-size: 0.4rem;
  line-height: 1.5;
}
.describ .skill {
  width: 80%;
  margin: auto;
  position: absolute;
  top: 9.7rem;
  left: 0;
  right: 0;
  font-size: 0.4rem;
}
.describ .skill .icon {
  width: 1.425rem;
  height: 1.425rem;
  position: relative;
  margin: auto;
  margin-top: 0.1rem;
  background: url(../assets/p3/skill_kuang.png);
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.describ .skill .icon img {
  width: 85%;
}
.title {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 1.5rem;
  width: 10rem;
  height: fit-content;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.titleFonte {
  width: 7.5rem;
  height: 1.125rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  content: url(../assets/p3/3tu_txt_1.png);
}
.decorate_R {
  width: 4rem;
  height: 0.7rem;
  content: url(../assets/p3/3tu_txt_2.png);
}
.decorate_L {
  width: 4rem;
  height: 0.7rem;
  transform: rotate(180deg);
  content: url(../assets/p3/3tu_txt_2.png);
}
.characterIndex {
  width: 15rem;
  height: 4.5rem;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
}

.characterIndex .icon_unActive {
  width: 2.27rem;
  height: 100%;
  z-index: 1;
  margin-top: 0.3rem;
  position: relative;
}
.characterIndex .icon {
  width: 2.83rem;
  height: 100%;
  z-index: 1;
  position: relative;
}
.characterIndex .icon .bottomDecorate {
  width: 0.43rem;
  height: 0.43rem;
  position: absolute;
  bottom: -0.05rem;
  margin: auto;
  left: 0;
  right: 0;
  content: url(../assets/p3/3tu_btn_8.png);
}
.characterIndex .icon .guid2Left {
  content: url(../assets/p3/3tu_btn_7.png);
  width: 1.2875rem;
  height: 1.48125rem;
  position: absolute;
  right: -1.8rem;
  top: 0.5rem;
  transform: rotate(180deg);
}
.characterIndex .icon .guid2Right {
  content: url(../assets/p3/3tu_btn_7.png);
  width: 1.2875rem;
  height: 1.48125rem;
  position: absolute;
  left: -1.8rem;
  top: 0.5rem;
}
.characterIndex .icon_unActive .bottomDecorate {
  width: 0.225rem;
  height: 0.46875rem;
  position: absolute;
  margin: auto;
  left: 0;
  bottom: 0.2rem;
  right: 0;
  content: url(../assets/p3/3tu_btn_9.png);
}
.characterIndex .bar {
  width: 100%;
  height: 0.33rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: url(../assets/p3/3tu_btn_10.png);
}
.RoleOffse {
  left: 0%;
}
@media (max-width: 450px) {
  .title {
    transform: scale(2);
    top: 5rem;
  }
  .roleBox {
    transform: scale(2);
    top: auto;
    bottom: 20%;
    left: 3.6rem;
  }
  .describ {
    transform: scale(2.2);
    bottom: auto;
    top: 15rem;
    right: auto;
    left: 7rem;
  }
  .RoleOffse {
    left: 10rem;
  }
  .characterIndex {
    bottom: 5rem;
    transform: scale(2);
  }
  .characterIndex .icon .bottomDecorate {
    transform: scale(2);
  }
  .characterIndex .icon_unActive .bottomDecorate {
    transform: scale(2);
  }
}
</style>