<template>
  <Home />
</template>

<script>
import Home from './components/homePage.vue'

export default {
  name: 'App',
  components: {
    Home
  },
  data(){
    const screenH=screen.height
    const screenW=screen.width
    return{
      screenH,
      screenW
    }
  }
}
</script>

<style>
</style>
