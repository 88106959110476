<template>
  <div class="page">
    <PagePagination :pageIndex="2" />
    <div class="title">
      <img class="decorate_L" alt="" />
      <img class="titleFonte" alt="" />
      <img class="decorate_R" alt="" />
    </div>
    <div class="videoBox">
      <img class="kuang" @click="pause" alt="" />
      <video
        preload
        ref="videoComp"
        id="videoplayer"
        poster='../assets/p2/2tu_bg_1.jpg'
        src="http://game-video.badambiz.com/video/nmd_adv/nmd_adv_224bce8965a3a5e7d39afee3e77d5de0.mp4"
      ></video>
      <img v-if="showPlayerIcon" class="icon" @click="play" alt="" />
    </div>
    <div class="describ">
      <img class="decorate topLeft" alt="">
      <img class="decorate topRight" alt="">
      <img class="decorate bottomRight" alt="">
      <img class="decorate bottomLeft" alt="">
      <p class="describTitle">
       <img class="decorate_L" alt="" />
       {{_T("游戏故事")}}
      <img class="decorate_R" alt="" />
      </p>
      <p class="text">
        {{_T('《沙漠传说：疾风之刃》故事说明')}}
      </p>
    </div>
  </div>
</template>

<script>
import PagePagination from "../widget/paginationWidget.vue";
function _T(t) {
  let translationMap = require("../translation.json");
  return translationMap[t] ? "\u202b" + translationMap[t] + "\u202b" : t;
}
export default {
  components: {
    PagePagination,
  },
  data() {
    return {
      showPlayerIcon: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    _T,
    init() {
      const videoPlayer = this.$refs.videoComp;
      videoPlayer.oncanplay = () => {
        this.showPlayerIcon = true;
      };
    },
    pause() {
      const videoPlayer = this.$refs.videoComp;
      videoPlayer.pause();
      console.log(videoPlayer.paused);
      setTimeout(() => {
        if (videoPlayer.paused) {
          this.showPlayerIcon = true;
        }
      }, 1);
    },
    play() {
      const videoPlayer = this.$refs.videoComp;
      videoPlayer.play();
      videoPlayer.onended = () => {
        this.showPlayerIcon = true;
      };
      videoPlayer.ontimeupdate = () => {
        this.showPlayerIcon = false;
      };
    },
  },
};
</script>

<style scoped>
.page {
  width: 100%;
  height: 100%;
  background: url(../assets/p2/2tu_bg_1.jpg);
  background-size: 100% 100%;
}

.title {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 1.5rem;
  width: 10rem;
  height: fit-content;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.titleFonte {
  width: 7.57rem;
  height: 1.3125rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  content: url(../assets/p2/2tu_txt_1.png);
}
.decorate_R {
  width: 4.368rem;
  height: 0.769rem;
  transform: rotate(180deg);
  content: url(../assets/p2/2tu_bg_4.png);
}
.decorate_L {
  width: 4.368rem;
  height: 0.769rem;
  content: url(../assets/p2/2tu_bg_4.png);
}
.videoBox {
  width: 16.76rem;
  height: 9.73rem;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.videoBox .kuang {
  content: url(../assets/p2/2tu_kuang_1.png);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.videoBox video {
  width: 99%;
  height: 99%;
  z-index: 0;
}
.videoBox .icon {
  width: 2.156rem;
  height: 2.156rem;
  content: url(../assets/p2/2tu_bofang_1.png);
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.describ {
  background:rgba(0, 0, 0, .5);
  border:1px solid #3e322b;
/*  background-image: url(../assets/p2/2tu_bg_3_left.png),
 url(../assets/p2/2tu_bg_3_right.png),
 url(../assets/p2/2tu_bg_3_middle.png);

background-repeat: no-repeat, no-repeat, repeat-x;
background-position: top left, top right, center top; */
  width: 23rem;
  height: 5rem;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top:0;
  bottom: -16rem;
}
.describ .decorate{
  content:url(../assets/p2/2tu_bg_3_decorate.png);
  width: 0.75rem;
  height:.75rem;
  position:absolute;
}
.describ .topLeft{
  top:0;
  left:0;
}
.describ .topRight{
  top:0;
  right:0;
  transform: rotate(90deg);
}
.describ .bottomRight{
  bottom:0;
  right:0;
  transform: rotate(180deg);
}
.describ .bottomLeft{
  bottom:0;
  left:0;
  transform: rotate(270deg);
}

.describ .describTitle{
  font-size:.675rem;
  color:#ffeda2;
  text-align: center;
      display: flex;
    justify-content: center;
    align-items: center;
}
.describ .decorate_L{
    width: 5.962rem;
  height: 0.15rem;
  margin-right: .5rem;
  content: url(../assets/p2/2tu_bg_2.png);
}
.describ .decorate_R{
    width: 5.962rem;
  height: 0.15rem;
  margin-left: .5rem;
  transform: rotate(180deg);
  content: url(../assets/p2/2tu_bg_2.png);
}
.describ .text{
  font-size:.4125rem;
  color:#ffeda2 ;
  width: 90%;
  margin:auto;
  text-align: right;
}
@media (max-width: 450px) {
  .page {
    width: 100%;
    height: 100%;
    background: url(../assets/mobile/2tu_bg_1.jpg);
    background-size: 100% 100%;
  }
  .videoBox{
    transform: scale(2);
    bottom:auto;
    top:33rem
  }
  .describ{
    transform: scale(1.4);
    height: 6.5rem;
     bottom:auto;
    top:51rem;
  }
  .title{
    transform: scale(2);
    top:5rem;
  }
}
</style>