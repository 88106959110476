<template>
  <div class="pos">
    <p class="cPage">0{{ pageIndex }}</p>
    <p class="tPage">04</p>
    <p class="describ">PAGE</p>
  </div>
  <img class="decorateUp" alt="" />
  <img class="decorateDown" alt="" />
</template>

<script>
export default {
  props: {
    pageIndex: Number,
  },
};
</script>

<style scoped>
.pos {
  width: 1.425rem;
  height: 1.33125rem;
  background: url(../assets/p1/1tu_yeshu_1.png);
  background-size: 100% 100%;
  position: absolute;
  top: 1.5rem;
  left: 1rem;
  z-index: 1;
}
.decorateUp {
  width: 0.15rem;
  height: 3.3rem;
  position: absolute;
  margin: auto;
  right: 3.5rem;
  top: 0;
  content: url(../assets/p1/1tu_bg_5.1.png);
  z-index: 1;
}
.decorateDown {
  width: 0.15rem;
  height: 10.95rem;
  position: absolute;
  margin: auto;
  right: 3.5rem;
  bottom: 0;
  content: url(../assets/p1/1tu_bg_5.png);
  z-index: 1;
}
p{
  color: #a7b3c0;
    font-size: .7rem;
    position: absolute;

    line-height: 0;
}
.cPage{
  color: #fff;
    top: -.7rem;
    font-size: .8rem;
    left: 0;
}
.tPage{
  right:0;
  bottom: -0.3rem;
}
.describ{
    font-size: .6rem;
  bottom:-1rem;
}

@media (max-width: 450px) {
 .pos{
     transform: scale(2);
     top:2.5rem;
     left:2rem;
 }
}
</style>