<template>
  <div class="page">
    <PagePagination :pageIndex="1" />
    <img class="title" alt="" />
    <!--     <div class="iconBox">
      <img class="playLeft" alt="" />
      <img class="iconPlayer" alt="" />
      <img class="playRight" alt="" />
    </div> -->
    <div class="info">
      <div class="qrCode"></div>
      <div class="storeBox">
        <img @click="toAppStore" src="../assets/p1/icon_appStore.png" alt="" />
        <img @click='toGooglePlay' src="../assets/p1/icon_googlePlay.png" alt="" />
      </div>
    </div>
    <div class="scrollTips">
      SCROLL
      <img class="tips" />
    </div>
  </div>
</template>

<script>
import PagePagination from "../widget/paginationWidget.vue";

export default {
  components: {
    PagePagination,
  },
  methods:{
    toAppStore(){
      const targetUrl='https://apps.apple.com/sa/app/%D8%A3%D8%B3%D8%B7%D9%88%D8%B1%D8%A9-%D8%A7%D9%84%D8%B5%D8%AD%D8%B1%D8%A7%D8%A1/id1619267131'
    window.location=targetUrl
   },
     toGooglePlay(){
      const targetUrl='https://play.google.com/store/apps/details?id=com.ziipin.parkour'
    window.location=targetUrl
   }
  }
};
</script>

<style scoped>
.page {
  width: 100%;
  height: 100%;
  background: url(../assets/p1/1tu_bg_1.jpg);
  background-size: 100% 100%;
}

.title {
  width: 13.76rem;
  height: 5.9rem;
  content: url(../assets/p1/1tu_logo_1.png);
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0rem;
}
.info {
  width: 8.05rem;
  height: 3.39rem;
  background: url(../assets/p1/1tu_bg_2.png);
  background-size: 100% 100%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom:5.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info .qrCode {
  width: 3rem;
  height: 3rem;
  background: url(../assets/p1/qrCode.jpg);
  background-size:100% 100%;
  border-radius: 0rem;
}
.info .storeBox {
  width: 4.2rem;
  height: 3rem;
  margin-left: 0.3rem;
  background: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.info .storeBox img {
  width: 100%;
}
.iconBox {
  width: fit-content;
  height: 2.14rem;
  position: absolute;
  margin: auto;
  top: 7rem;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-items: center;
  align-items: center;
}
.iconPlayer {
  width: 2.14rem;
  height: 2.14rem;
  margin-left: 1rem;
  margin-right: 1rem;
  content: url(../assets/p1/1tu_bofang_1.png);
}
.playLeft {
  width: 4.97rem;
  height: 0.9rem;
  content: url(../assets/p1/1tu_bg_6.png);
}
.playRight {
  width: 4.97rem;
  height: 0.9rem;
  content: url(../assets/p1/1tu_bg_6.png);
  transform: rotate(180deg);
}
.scrollTips {
  color: #fff;
  font-size: 0.6rem;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 1.3rem;
}
.tips {
  width: 1.2rem;
  height: 0.5625rem;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: -0.6rem;
  content: url(../assets/p1/1tu_btn_1.png);
}
@media (max-width: 450px) {
  .page {
    width: 100%;
    height: 100%;
    background: url(../assets/mobile/1tu_bg_1.jpg);
    background-size: 100% 100%;
  }
  .title {
    transform: scale(2);
    top: 5%;
  }
  .info {
    transform: scale(3);
    bottom: 25%;
  }
}
</style>